import icon from './icon.js';

const insertEditButtonPlugin = (editor) => {
  const openDialog = (apiRoot) => {
    const node = editor.selection.getNode();
    const isButton = node.nodeName === 'A' && node.hasAttribute('data-title-button');

    // Obtener atributos y estilos actuales si estamos editando un botón
    const href = isButton ? editor.dom.getAttrib(node, 'href') : '';
    const text = isButton ? node.textContent : '';
    const title = isButton ? editor.dom.getAttrib(node, 'title') : '';
    const target = isButton ? (editor.dom.getAttrib(node, 'target') === '_blank' ? 'new' : 'current') : 'current';
    const typeList = isButton ? (node.className.split(' ').find(cls => cls.startsWith('button-'))?.replace('button-', '') || 'default') : 'default';
    const fullWidth = isButton ? node.className.includes('full-width') : false;
    const textColor = isButton ? editor.dom.getStyle(node, 'color') : '';
    const buttonColor = isButton ? editor.dom.getStyle(node, 'background-color') : '';
    const borderColor = isButton ? editor.dom.getStyle(node, 'border-color') : '';
    const borderRadius = isButton ? editor.dom.getStyle(node, 'border-radius').replace('px', '') : '';
    const borderWidth = isButton ? editor.dom.getStyle(node, 'border-width').replace('px', '') : '';
    const borderStyle = isButton ? editor.dom.getStyle(node, 'border-style') : '';
    const [paddingTop, paddingLeft] = isButton ? editor.dom.getStyle(node, 'padding').split(' ') : ['', ''];
    const [marginTop, marginLeft] = isButton ? editor.dom.getStyle(node, 'margin').split(' ') : ['', ''];
    const verticalPadding = (paddingTop && parseInt(paddingTop)?.toString()) || '';
    const horizontalPadding = ((paddingTop || paddingLeft) && parseInt(paddingLeft ?? paddingTop)?.toString()) || '';
    const verticalSpace = (marginTop && parseInt(marginTop)?.toString()) || '';
    const horizontalSpace = ((marginTop || marginLeft) && parseInt(marginLeft ?? marginTop)?.toString()) || '';
    const textAlign = isButton ? editor.dom.getStyle(node, 'text-align') : 'left';

    if (apiRoot?.setActive) apiRoot.setActive(true);

    editor.windowManager.open({
      title: isButton ? 'Edit Button' : 'Insert Button',
      body: {
        type: 'tabpanel',
        tabs: [
          {
            name: 'general',
            title: 'General',
            items: [
              {
                type: 'input',
                name: 'url',
                label: 'URL'
              },
              {
                type: 'input',
                name: 'text',
                label: 'Text to display'
              },
              {
                type: 'input',
                name: 'title',
                label: 'Title'
              },
              {
                type: 'grid',
                columns: 2,
                items: [
                  {
                    type: 'selectbox',
                    name: 'openLinkIn',
                    label: 'Open link in...',
                    items: [
                      { text: 'Current window', value: 'current' },
                      { text: 'New window', value: 'new' }
                    ]
                  },
                  {
                    type: 'selectbox',
                    name: 'typeList',
                    label: 'Type',
                    items: [
                      { text: 'Default', value: 'default' },
                      { text: 'Squared', value: 'squared' },
                      { text: 'Rounded', value: 'rounded' },
                      { text: 'Outlined', value: 'outlined' },
                      { text: 'Unstyled', value: 'unstyled' }
                    ]
                  },
                  {
                    type: 'selectbox',
                    name: 'textAlign',
                    label: 'Text Alignment',
                    items: [
                      { text: 'Left', value: 'left' },
                      { text: 'Center', value: 'center' },
                      { text: 'Right', value: 'right' }
                    ]
                  }
                ]
              },
              {
                type: 'checkbox',
                name: 'fullWidth',
                label: 'Full Width'
              },
            ]
          },
          {
            name: 'advanced',
            title: 'Advanced',
            items: [
              {
                type: 'grid',
                columns: 2,
                items: [
                  {
                    type: 'colorinput',
                    name: 'textColorPicker',
                    label: 'Text color'
                  },
                  {
                    type: 'colorinput',
                    name: 'buttonColorPicker',
                    label: 'Button color'
                  },
                  {
                    type: 'colorinput',
                    name: 'borderColorPicker',
                    label: 'Border color'
                  },
                  {
                    type: 'input',
                    name: 'borderWidth',
                    label: 'Border width',
                    inputMode: 'numeric'
                  },
                  {
                    type: 'input',
                    name: 'borderRadius',
                    label: 'Border radius',
                    inputMode: 'numeric'
                  },
                  {
                    type: 'selectbox',
                    name: 'borderStyle',
                    label: 'Border Style',
                    items: [
                      { text: '', value: '' },
                      { text: 'dashed', value: 'dashed' },
                      { text: 'dotted', value: 'dotted' },
                      { text: 'double', value: 'double' },
                      { text: 'groove', value: 'groove' },
                      { text: 'hidden', value: 'hidden' },
                      { text: 'inset', value: 'inset' },
                      { text: 'none', value: 'none' },
                      { text: 'outset', value: 'outset' },
                      { text: 'ridge', value: 'ridge' },
                      { text: 'solid', value: 'solid' },
                    ]
                  },
                ]
              },
              {
                type: 'grid',
                columns: 2,
                items: [
                  {
                    type: 'input',
                    name: 'verticalSpace',
                    label: 'Vertical space',
                    inputMode: 'numeric'
                  },
                  {
                    type: 'input',
                    name: 'horizontalSpace',
                    label: 'Horizontal space',
                    inputMode: 'numeric'
                  },
                  {
                    type: 'input',
                    name: 'verticalPadding',
                    label: 'Vertical padding',
                    inputMode: 'numeric'
                  },
                  {
                    type: 'input',
                    name: 'horizontalPadding',
                    label: 'Horizontal padding',
                    inputMode: 'numeric'
                  }
                ]
              }
            ]
          }
        ]
      },
      initialData: {
        url: href,
        text: text,
        title: title,
        openLinkIn: target,
        typeList: typeList,
        textColorPicker: textColor,
        buttonColorPicker: buttonColor,
        borderColorPicker: borderColor,
        borderWidth: borderWidth,
        borderRadius: borderRadius,
        borderStyle: borderStyle,
        verticalPadding: verticalPadding,
        horizontalPadding: horizontalPadding,
        verticalSpace: verticalSpace,
        horizontalSpace: horizontalSpace,
        fullWidth: fullWidth,
        textAlign: textAlign
      },
      buttons: [
        {
          type: 'cancel',
          text: 'Cancel'
        },
        {
          type: 'submit',
          text: 'Save',
          buttonType: 'primary'
        }
      ],
      onSubmit: (api) => {
        const data = api.getData();
        const { url, text, title, openLinkIn, typeList, textColorPicker, buttonColorPicker, borderColorPicker, borderWidth, borderRadius, verticalPadding, horizontalPadding, verticalSpace, horizontalSpace, borderStyle, fullWidth, textAlign } = data;
        console.log("🚀 ~ openDialog ~ horizontalPadding:", horizontalPadding)

        const _verticalPadding = verticalPadding ? `${parseInt(verticalPadding)}px` : '';
        const _horizontalPadding = horizontalPadding ? `${parseInt(horizontalPadding)}px` : '';
        const _padding = (_verticalPadding || _horizontalPadding) ? `padding: ${_verticalPadding} ${_horizontalPadding};` : '';

        const _borderWidth = borderWidth ? `${parseInt(borderWidth)}px` : '';
        const _borderRadius = borderRadius ? `${parseInt(borderRadius)}px` : '';
        const _verticalSpace = verticalSpace ? `${parseInt(verticalSpace)}px` : '';
        const _horizontalSpace = horizontalSpace ? `${parseInt(horizontalSpace)}px` : '';
        const _margin = (verticalSpace || horizontalSpace) ? `margin: ${_verticalSpace} ${_horizontalSpace};` : '';
        const _borderStyle = borderStyle ? `border-style: ${borderStyle};` : '';
        const _textAlign = textAlign ? `text-align: ${textAlign};` : '';

        const target = openLinkIn === 'new' ? '_blank' : '_self';
        const buttonClass = `button button-${typeList} ${fullWidth ? 'full-width' : ''}`;
        const buttonStyle = `
            ${textColorPicker ? `color: ${textColorPicker};` : ''}
            ${buttonColorPicker ? `background-color: ${buttonColorPicker};` : ''}
            ${borderColorPicker ? `border-color: ${borderColorPicker};` : ''}
            ${_borderWidth ? `border-width: ${_borderWidth};` : ''}
            ${_borderRadius ? `border-radius: ${_borderRadius};` : ''}
            ${_padding}
            ${_margin}
            ${_borderStyle}
            ${_textAlign}
        `;

        if (isButton) {
          editor.dom.setAttrib(node, 'href', url);
          editor.dom.setAttrib(node, 'title', title);
          editor.dom.setAttrib(node, 'target', target);
          editor.dom.setAttrib(node, 'class', buttonClass);
          editor.dom.setStyle(node, 'color', textColorPicker);
          editor.dom.setStyle(node, 'background-color', buttonColorPicker);
          editor.dom.setStyle(node, 'border-color', borderColorPicker);
          editor.dom.setStyle(node, 'border-radius', _borderRadius);
          editor.dom.setStyle(node, 'border-style', borderStyle);
          editor.dom.setStyle(node, 'border-width', _borderWidth);
          editor.dom.setStyle(node, 'padding', `${_verticalPadding} ${_horizontalPadding}`);
          editor.dom.setStyle(node, 'margin', `${_verticalSpace} ${_horizontalSpace}`);
          editor.dom.setStyle(node, 'text-align', textAlign);
          node.textContent = text;
        } else {
          editor.insertContent(`<a href="${url}" title="${title}" target="${target}" class="${buttonClass}" data-title-button="${title}" style="${buttonStyle}">${text}</a>`);
        }

        if (apiRoot?.setActive) apiRoot.setActive(false);
        api.close();
      }
    });
  };

  editor.ui.registry.addIcon('button_icon', icon());

  const onSetup = (api) => {
    const updateButtonState = () => {
      const node = editor.selection.getNode();
      if (api?.setActive) api.setActive(node.nodeName === 'A' && node.hasAttribute('data-title-button'));
    };

    editor.on('nodeChange', updateButtonState);
    return () => {
      editor.off('nodeChange', updateButtonState);
    };
  };

  editor.ui.registry.addButton('insertEditButton', {
    icon: 'button_icon',
    tooltip: 'Insert/Edit Button',
    onAction: () => openDialog(editor),
    onSetup
  });

  editor.ui.registry.addMenuItem('insertEditButton', {
    icon: 'button_icon',
    text: 'Insert/Edit Button',
    onAction: () => openDialog(editor),
    onSetup
  });

  editor.addCommand('mceInsertEditButton', () => openDialog(editor));

  return {
    getMetadata: () => ({
      name: 'button',
      url: 'https://www.tiny.cloud/docs-plugins/tinymce-advanced/button'
    })
  };
};

export default insertEditButtonPlugin;