import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { getApiKeys } from '../../api/client/config';
import { getClienteSecreto, verificar_tarjeta, cancelar_verificar_tarjeta, crear_suscripcion_paypal, comprar_suscripcion } from '../../api/client/pagos';
import { actualizar_metodo_pago, updateSubscription } from '../../api/client/mi_suscripcion';
import { getPerfil } from '../../api/client/perfil';
import { getProgramas } from '../../api/client/programas';
import { optionsToast } from '../../config/toast';
import { ButtonCustom, ButtonPrimary } from '../../components/Button';
import { HeaderSimple } from '../../components/Header';
import SEO from '../../components/SEO';
import Loading from '../../components/Loading';
import { useCustomState } from '../../hooks/useCustomState';
import { isEmpty } from '../../utils';
import { dayjs_custom } from '../../utils/dayjs_custom';
import parseErrorMessage from '../../utils/parseErrorMessage';
import generarCodigoFactura from '../../utils/generarCodigoFactura';
import { colors } from '../../config/colors';
import { getCupones } from '../../api/client/cupones';
import { ProgressSpinner } from 'primereact/progressspinner';
import calculateDiscount from '../../utils/calculateDiscount';

let stripePromise = null, apiKeysRemote = {};

const IconCheck = memo((props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20.75 20.75' {...props}>
    <circle fill={props.color_bg || '#fff'} cx={10.38} cy={10.38} r={10.38} />
    <path
      fill={props.color || '#df9cd4'}
      d='M15.01 6.19c-.27 0-.53.12-.72.32-2 2.01-3.53 3.68-5.39 5.58l-1.98-1.67a1 1 0 0 0-.76-.24c-.28.02-.53.16-.71.37s-.27.49-.24.76c.02.28.16.53.37.71l2.72 2.3c.2.17.46.26.72.24.26-.01.51-.12.69-.3 2.25-2.26 3.85-4.06 6.06-6.27.2-.2.32-.47.32-.75s-.11-.55-.32-.75c-.2-.2-.48-.3-.76-.3'
    />
  </svg>
));

let timeout;
function checkFocus() {
  if (!document.hasFocus()) {
    window.close();
    if (window.closed) clearTimeout(timeout);
  } else {
    timeout = setTimeout(checkFocus, 2000);
  }
}

const SuscripcionActualizada = memo(() => {
  const openPopup = () => {
    const url = 'jimefitmom://app?success-payment=true';
    try {
      checkFocus();
      timeout = setTimeout(() => {
        clearTimeout(timeout);
      }, 30000);
      window.open(url, '_self');
    } catch (error) {
      window.location.href = url;
    }
  };

  return (
    <div className='flex flex-col items-center justify-center max-w-[440px] mx-auto'>
      <div className='inline-flex rounded-full'>
        <IconCheck height={56} width={56} color_bg={colors.Crayola} color={colors.white2} />
      </div>
      <div className='mt-3 mb-6'>
        <h1 className='font-golos-semibold leading-1 text-Crayola text-lg text-center mb-1'>La suscripción se ha actualizado correctamente.</h1>
        <h1 className='font-golos-semibold leading-1 text-Crayola text-md text-center'>Ya puedes cerrar está pagina y regresar a la aplicación.</h1>
      </div>
      <ButtonCustom
        classNameButton={"bg-Orchid hover:!bg-OrchidHover !border-Orchid rounded-full"}
        onClick={openPopup}
        text="Regresar a la aplicación"
      />
    </div>
  );
});

const ActualizarSuscripcion = () => {
  const [state, setState] = useCustomState({
    isLoading: true,
    isCompleted: false,
    profile: null,
    programas: null,
    urls: {
      cancel: "jimefitmom://app?cancel-payment=true",
      success: "jimefitmom://app?success-payment=true",
    }
  });
  const [searchParams] = useSearchParams();
  const queryParams = Object.fromEntries(searchParams.entries());

  const user = useMemo(() => ({
    couponID: queryParams?.couponID,
    programID: queryParams.programID,
    cycleType: queryParams.cycleType,
    userID: queryParams.userID,
    id: queryParams.clientID,
    email: queryParams.email,
    profile: state.profile
  }), [queryParams, state.profile]);

  const onGetProfile = useCallback(async () => {
    if (isEmpty(user?.id) || isEmpty(user?.email)) return setState({ isLoading: false });
    try {
      let programas;
      if (["actualizar-programa", "comprar-suscripcion"].includes(queryParams.type)) {
        try {
          programas = await getProgramas({ id: user?.programID });
        } catch (error) { }
      }
      const response = await getPerfil({ id: user?.id, email: user?.email, loginDevice: "app" });
      if (response?.status === true) {
        setState({ isLoading: false, profile: response?.data, programas: programas?.data });
      }
      return response;
    } catch (error) {
      const message = parseErrorMessage(error);
      setState({ isLoading: false });
      return { error, message };
    }
  }, [queryParams.type, user?.id, user?.email, user?.programID, setState]);

  const openPopup = (url) => {
    try {
      checkFocus();
      timeout = setTimeout(() => {
        clearTimeout(timeout);
      }, 30000);
      window.open(url, '_self');
    } catch (error) {
      window.location.href = url;
    }
  };

  const onCancel = useCallback(async () => {
    openPopup(state.urls.cancel);
  }, [state.urls.cancel]);

  const onFinishUpdate = useCallback(async (isCancel = false) => {
    if (!isCancel) {
      setState({ isCompleted: true });
      window.history.replaceState({}, null, "/actualizar-suscripcion?isCompleted=true");
    }

    const url = isCancel ? state.urls.cancel : state.urls.success;
    setTimeout(_ => openPopup(url), 500);
  }, [state.urls.cancel, state.urls.success, setState]);

  const isValid = useCallback(() => {
    if (
      isEmpty(user?.userID) ||
      isEmpty(user?.id) ||
      isEmpty(user?.email) ||
      isEmpty(user?.profile)
    ) return false;

    return true;
  }, [user]);

  useEffect(() => {
    onGetProfile();
  }, [queryParams.type, user?.id, user?.email, user?.programID, onGetProfile]);

  return (
    <>
      <SEO
        title="Actualizar Suscripción"
        description="Fitness Coach Certificada Pre y Posnatal y Especialista en Ejercicios Correctivos, en Diástasis y en Core."
      />
      <HeaderSimple
        theme="white"
      />
      <div className='flex-1 bg-Magnolia'>
        <div className="xl:container flex flex-col gap-3 mx-auto px-6 py-6 w-full">
          <div className='flex-1 flex flex-col gap-8 bg-white p-8 rounded-xl'>
            {state.isLoading ? (
              <Loading />
            ) : (
              <>
                {(state.isCompleted || queryParams?.isCompleted === "true") ? (
                  <SuscripcionActualizada />
                ) : (
                  <>
                    {isValid() === true ? (
                      <ActualizarMetodoPagoProvider
                        user={user}
                        onFinishUpdate={onFinishUpdate}
                        onCancel={onCancel}
                        type={queryParams?.type}
                        paymentType={queryParams?.paymentType}
                        onlyPaypal={queryParams?.onlyPaypal}
                        programas={state.programas}
                      />
                    ) : (
                      <div style={{ maxWidth: 440, margin: "auto" }}>
                        <h1 className='font-golos-bold leading-1 text-Crayola text-lg lg:text-xl xl:text-2xl text-center mb-2'>¡Lo sentimos!</h1>
                        <h1 className='font-golos-semibold leading-1 text-Crayola text-lg lg:text-xl xl:text-2xl text-center'>La pantalla no puede ser mostrada debido a un problema con la información.</h1>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const ActualizarMetodoPagoProvider = ({ type, paymentType, onlyPaypal, user, programas, onCancel, onFinishUpdate }) => {
  const [stateMetodoPago, setStateMetodoPago] = useCustomState({
    tabSelected: paymentType === "PayPal" ? "paypal" : "card",
  });
  const [state, setState] = useCustomState({
    setupIntentId: null,
    optionsStripe: {
      clientSecret: null,
      paymentMethodCreation: "manual",
    }
  });

  useEffect(() => {
    updateOptionsStripe({ user });

    // eslint-disable-next-line
  }, [user?.userID]);

  const updateOptionsStripe = useCallback(({ user: userLocal, onlySetupIntent, setupIntentId }) => {
    const _user = userLocal || user;
    if (onlySetupIntent && setupIntentId) {
      return setState({ setupIntentId: setupIntentId });
    }
    if (!isEmpty(_user?.userID)) {
      getClienteSecreto(_user?.userID).then(response => {
        if (!onlySetupIntent) setState({ clientSecret: response?.data?.client_secret }, 'optionsStripe');
        setState({ setupIntentId: response?.data?.id });
      });
    }
  }, [user, setState]);

  return (
    <div className='animate__animated animate__fadeIn'>
      <div className='flex flex-col gap-8'>
        <div className='flex items-center justify-between flex-col md:flex-row gap-6'>
          <div>
            <h1 className='font-golos-semibold text-Crayola text-2xl'>{onlyPaypal ? "Pagar con PayPal" : "Selecciona tú método de pago"}</h1>
          </div>
          {!onlyPaypal && (
            <div className='col-span-1 flex flex-wrap items-center gap-3'>
              <button
                type="button"
                className={`${stateMetodoPago?.tabSelected === 'card' ? "font-golos-medium bg-Crayola text-white" : "text-Crayola"} border border-Crayola text-sm px-4 py-3 rounded-lg active:scale-95 transition-all`}
                onClick={() => setStateMetodoPago({ tabSelected: "card" })}
              >
                Débito / Crédito
              </button>
              <button
                type="button"
                className={`${stateMetodoPago?.tabSelected === 'paypal' ? "font-golos-medium bg-Crayola text-white" : "text-Crayola"} border border-Crayola text-sm px-5 py-3 rounded-lg active:scale-95 transition-all`}
                onClick={() => setStateMetodoPago({ tabSelected: "paypal" })}
              >
                PayPal
              </button>
            </div>
          )}
        </div>
        <div className='mt-2'>
          {(stateMetodoPago.tabSelected === "card") && (
            <>
              {(isEmpty(state.optionsStripe?.clientSecret) || isEmpty(state.setupIntentId) || !stripePromise) ? (
                <Loading />
              ) : (
                <Elements key={state.optionsStripe?.clientSecret} stripe={stripePromise} options={state.optionsStripe}>
                  <ActualizarMetodoPago
                    type={type}
                    user={user}
                    programas={programas}
                    methodSelected={stateMetodoPago.tabSelected}
                    onCancel={onCancel}
                    _onFinishUpdate={onFinishUpdate}
                    updateOptionsStripe={updateOptionsStripe}
                  />
                </Elements>
              )}
            </>
          )}
          {(stateMetodoPago.tabSelected === "paypal" && apiKeysRemote?.paypal) && (
            <PayPalScriptProvider
              options={{
                clientId: apiKeysRemote.paypal,
                intent: "subscription",
                disableFunding: "card,credit,paylater,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo",
                vault: true,
                locale: "es_MX"
              }}
            >
              <ActualizarMetodoPago
                type={type}
                user={user}
                programas={programas}
                methodSelected={stateMetodoPago.tabSelected}
                onCancel={onCancel}
                _onFinishUpdate={onFinishUpdate}
              />
            </PayPalScriptProvider>
          )}
        </div>
      </div>
    </div>
  );
}

const StripeForm = ({ type, programas, user, onCancel, _onFinishUpdate, updateOptionsStripe }) => {
  const [state, setState] = useCustomState({
    sending: {
      pay: false,
      cupon: false,
    },
    metodo_pago: {
      codigo_postal: "",
      cupon: "",
    },
    cupon_detalles: null,
  });
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const handlePaymentError = useCallback((title, _message) => {
    setState({ pay: false }, 'sending');
    message(title, _message);
  }, [setState]);

  const onSearchCoupon = useCallback(async (_discount_code) => {
    if (state.cupon_detalles?.code !== _discount_code && !isEmpty(_discount_code)) {
      try {
        setState({ cupon: true }, 'sending');
        setState({ cupon: _discount_code });

        const cupon = await getCupones({ code: _discount_code, programID: user?.programID, planType: user?.cycleType });

        if (cupon?.status === true) {
          const _cupon = cupon?.data?.[0];
          setState({ cupon: false }, 'sending');
          if (_cupon?.limit_users > 0 && _cupon?.cupones_restantes <= 0) {
            setState({ cupon_detalles: null });
            return message("El cupón ya no está disponible o se ha agotado");
          } else if (_cupon?.isValid !== true) {
            setState({ cupon_detalles: null });
            return message("El cupón ha expirado o no es válido");
          }

          setState({ cupon_detalles: _cupon });
        } else {
          setState({ cupon_detalles: null });
          setState({ cupon: false }, 'sending');
          message('Lo sentimos, el cupón no existe o es incorrecto.');
        }
      } catch (error) {
        setState({ cupon_detalles: null });
        setState({ cupon: false }, 'sending');
        message(parseErrorMessage(error) || 'Lo sentimos, ocurrió un error con el cupón de descuento. Por favor, inténtalo de nuevo más tarde.');
      }
    } else if (isEmpty(_discount_code)) {
      setState({ cupon_detalles: null });
    }
  }, [user?.cycleType, user?.programID, state, setState]);

  const handleCheckoutStripe = useCallback(async (event) => {
    event.preventDefault();
    if ((type !== "comprar-suscripcion" && isEmpty(user?.profile?.subscription?.id)) || isEmpty(user?.userID)) {
      return message("Información incompleta", "Se encontro información requerida que no se esta enviando");
    }
    if (isEmpty(state.metodo_pago.codigo_postal)) {
      return message("Información incompleta", "El código postal es requerido para continuar");
    }
    if (!isEmpty(user?.profile?.subscription?.nextBillingDate)) {
      const isSameDay = (startTime) => {
        const currentDate = dayjs_custom(new Date(), { tz: null, noTz: true, fromFormat: 'YYYY-MM-DD' });
        const startTimeDate = dayjs_custom(startTime, { tz: null, noTz: true, fromFormat: 'YYYY-MM-DD' });
        return currentDate.isSame(startTimeDate, 'day');
      };
      if (isSameDay(user?.profile?.subscription?.nextBillingDate)) return message(null, "No se permiten realizar modificaciones en la fecha de facturación.");
    }

    try {
      setState({ pay: true }, 'sending');

      elements.submit();
      const { error: errorCreatePayment, paymentMethod } = await stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: {
            name: user?.profile?.fullName,
            address: {
              country: "MX",
              postal_code: state.metodo_pago.codigo_postal
            }
          },
        },
      });

      if (errorCreatePayment) {
        setState({ pay: false }, 'sending');
        return message(null, errorCreatePayment?.message || 'Lo sentimos, ocurrió un error al crear el método de pago. Por favor, inténtalo de nuevo más tarde.');
      } else {
        const _verificar_tarjeta = await verificar_tarjeta({
          userID: user?.userID,
          paymentMethodId: paymentMethod?.id,
        });

        if (_verificar_tarjeta?.status === "requires_action") {
          const client_secret = _verificar_tarjeta?.data?.paymentIntent?.client_secret;
          const paymentIntentVerified = await stripe.handleNextAction({ clientSecret: client_secret });

          if (paymentIntentVerified.error) {
            // const getPaymentIntent = await stripe.retrievePaymentIntent(client_secret);
            try {
              await cancelar_verificar_tarjeta({ paymentIntentId: _verificar_tarjeta?.data?.paymentIntent?.id });
            } catch (error) {
              console.log("🚀 ~ handleCheckoutStripe ~ error:", error);
            }

            return handlePaymentError(null, paymentIntentVerified.error?.message);
          }
        }

        try {
          await cancelar_verificar_tarjeta({ paymentIntentId: _verificar_tarjeta?.data?.paymentIntent?.id });
        } catch (error) { }

        if (type === "comprar-suscripcion") {
          const _comprar_suscripcion = await comprar_suscripcion({
            couponID: user?.couponID || state.cupon_detalles?.id,
            userID: user?.userID,
            programID: user?.programID,
            cycleType: user?.cycleType,
            paymentMethodId: paymentMethod?.id,
            cardBrand: paymentMethod?.card?.brand,
            cardLast4: paymentMethod?.card?.last4,
            expirationMonth: paymentMethod?.card?.exp_month,
            expirationYear: paymentMethod?.card?.exp_year,
          });

          if (["active", "trialing"].includes(_comprar_suscripcion?.status)) {
            _onFinishUpdate();
          }
        } else if (type === "actualizar-programa") {
          const _actualizar_suscripcion = await updateSubscription({
            userID: user?.userID,
            programID: user?.programID,
            id: user?.profile?.subscription?.id,
            cycleType: user?.cycleType,
          });

          if (_actualizar_suscripcion?.status === true) {
            _onFinishUpdate();
          }
        } else {
          const _actualizar_suscripcion = await actualizar_metodo_pago({
            id: user?.profile?.subscription?.id,
            userID: user?.userID,
            paymentMethodId: paymentMethod?.id,
            cardBrand: paymentMethod?.card?.brand,
            cardLast4: paymentMethod?.card?.last4,
            expirationMonth: paymentMethod?.card?.exp_month,
            expirationYear: paymentMethod?.card?.exp_year,
            methodPayment: "stripe",
          });
          if (["active", "trialing", "paid", "APPROVED", 'incomplete'].includes(_actualizar_suscripcion?.status || _actualizar_suscripcion?.data?.status)) {
            // message("Suscripción actualizada", "¡La suscripción se ha actualizado exitosamente!");
            _onFinishUpdate();
          }
        }
        setState({ pay: false }, 'sending');
      }
    } catch (error) {
      console.log("🚀 ~ handleCheckoutStripe ~ error:", error);
      setState({ pay: false }, 'sending');
      message('Ocurrió un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al realizar el pago. Por favor, inténtalo de nuevo más tarde.');
      if (parseErrorMessage(error)?.indexOf("this SetupIntent because it has been canceled") !== -1) {
        const errors = error.response.data.errors;
        if (!isEmpty(errors?.setupIntentId)) updateOptionsStripe({ onlySetupIntent: true, setupIntentId: errors?.setupIntentId });
      }
      if (parseErrorMessage(error)?.indexOf("cannot update this SetupIntent because it has already succeeded") !== -1) {
        updateOptionsStripe({ onlySetupIntent: true });
      }
    }
  }, [elements, type, user, state.metodo_pago, state.cupon_detalles, setState, _onFinishUpdate, handlePaymentError, stripe, updateOptionsStripe]);

  return (
    <form className='space-y-4 animate__animated animate__fadeIn' onSubmit={(event) => handleCheckoutStripe(event)}>
      <PaymentElement
        id="payment-element"
        options={{
          business: {
            name: "JimeFitMom"
          },
          layout: {
            type: 'tabs',
          },
          fields: {
            billingDetails: {
              name: "never",
              address: {
                country: 'never',
                postalCode: "never"
              }
            }
          },
          paymentMethodOrder: ['card', 'apple_pay', 'google_pay', 'oxxo', 'paypal', 'blik', 'klarna', 'zip']
        }}
      />
      <input
        name="codigo_postal"
        type="text"
        placeholder="Código postal *"
        className="bg-white !outline-none border border-[#e6e6e6] focus-within:border-indigo-500 focus-within:ring-indigo-500 placeholder-gray-500 px-3 py-3 rounded-lg text-sm text-gray-900 w-full transition-all [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        value={state.metodo_pago.codigo_postal}
        onChange={(event) => {
          const inputValue = event.target.value;
          // if (inputValue.length <= 5) {
          setState({ codigo_postal: inputValue }, "metodo_pago");
          // }
        }}
      />
      {(type === "comprar-suscripcion") && (
        <>
          <div className="relative rounded-lg bg-white border border-[#e6e6e6] focus-within:border-indigo-500 focus-within:ring-indigo-500 flex items-center transition-all">
            <input
              name="cupon"
              type="text"
              placeholder="Cupón"
              className="autofill:shadow-[inset_0_0_0px_1000px_rgb(255,255,255)] !border-0 !outline-none !ring-0 placeholder-gray-500 px-3 py-3 rounded-lg text-sm text-gray-900 uppercase w-full transition-all"
              value={state.metodo_pago.cupon}
              onChange={(event) => setState({ cupon: event.target.value }, "metodo_pago")}
            />
            <div className="flex flex-row gap-1 justify-center">
              {state.sending.cupon ? (
                <ProgressSpinner style={{ width: '1rem', height: '1rem', margin: 0, marginRight: 10 }} strokeWidth="3" animationDuration=".5s" />
              ) : (
                <>
                  {state.cupon_detalles?.id && (
                    <button type="button" className='py-3 px-2 text-Crayola text-sm underline active:scale-90 transition-all' onClick={() => setState((prevState) => ({ ...prevState, cupon_detalles: null, metodo_pago: { ...prevState.metodo_pago, cupon: "" } }))}>Eliminar</button>
                  )}
                  <button type="button" className='py-3 pl-2 pr-3 text-Crayola text-sm underline active:scale-90 transition-all' onClick={() => onSearchCoupon(state.metodo_pago.cupon)}>Aplicar</button>
                </>
              )}
            </div>
          </div>
          {state.cupon_detalles?.id && (
            <div className='flex justify-end w-full'>
              <p className='font-golos text-sm text-right'>
                <span className='mr-1'>Descuento del cupón:</span>
                <span className='text-red-600'>{state.cupon_detalles?.type === 'porcentaje' ? `${state.cupon_detalles?.discount}%` : `$${parseFloat(state.cupon_detalles?.discount)?.toFixed(2)}`}</span>
              </p>
            </div>
          )}
        </>
      )}
      <div className='flex flex-wrap justify-center gap-3 pt-8'>
        <ButtonPrimary
          text={"Regresar"}
          // withIconBack={true}
          classNameContainer="!border-DeepKoamaru"
          classNameButton={"!py-3 !px-14 !bg-DeepKoamaru hover:!bg-[#211155]"}
          type="button"
          onClick={onCancel}
        />
        <ButtonPrimary
          text={"Continuar"}
          classNameButton={"!py-3 !px-14"}
          type="button"
          loading={state.sending.pay}
          onClick={(event) => handleCheckoutStripe(event, user, state, setState, navigate, elements)}
        />
      </div>
    </form>
  );
}

const PayPalReducer = ({ children }) => {
  const [{ isPending }] = usePayPalScriptReducer();

  return children({ isPending });
}

const PayPalForm = ({ type, programas, user, _onFinishUpdate }) => {
  const invoiceCode = useRef(generarCodigoFactura());
  const [state, setState] = useCustomState({
    sending: {
      cupon: false,
    },
    metodo_pago: {
      cupon: "",
    },
    cupon_detalles: null,
  });

  const programa_seleccionado = useMemo(() => programas?.length > 0 && programas?.find(programa => parseInt(programa.id) === parseInt(user?.programID)), [user?.programID, programas]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const onSearchCoupon = useCallback(async (_discount_code) => {
    if (state.cupon_detalles?.code !== _discount_code && !isEmpty(_discount_code)) {
      try {
        setState({ cupon: true }, 'sending');
        setState({ cupon: _discount_code });

        const cupon = await getCupones({ code: _discount_code, programID: user?.programID, planType: user?.cycleType });

        if (cupon?.status === true) {
          const _cupon = cupon?.data?.[0];
          setState({ cupon: false }, 'sending');
          if (_cupon?.limit_users > 0 && _cupon?.cupones_restantes <= 0) {
            setState({ cupon_detalles: null });
            return message("El cupón ya no está disponible o se ha agotado");
          } else if (_cupon?.isValid !== true) {
            setState({ cupon_detalles: null });
            return message("El cupón ha expirado o no es válido");
          }

          setState({ cupon_detalles: _cupon });
        } else {
          setState({ cupon_detalles: null });
          setState({ cupon: false }, 'sending');
          message('Lo sentimos, el cupón no existe o es incorrecto.');
        }
      } catch (error) {
        setState({ cupon_detalles: null });
        setState({ cupon: false }, 'sending');
        message(parseErrorMessage(error) || 'Lo sentimos, ocurrió un error con el cupón de descuento. Por favor, inténtalo de nuevo más tarde.');
      }
    } else if (isEmpty(_discount_code)) {
      setState({ cupon_detalles: null });
    }
  }, [user?.cycleType, user?.programID, state, setState]);

  const createSubscriptionPaypal = useCallback(async () => {
    if (!isEmpty(user?.profile?.subscription?.nextBillingDate)) {
      const isSameDay = (startTime) => {
        const currentDate = dayjs_custom(new Date(), { tz: null, noTz: true, fromFormat: 'YYYY-MM-DD' });
        const startTimeDate = dayjs_custom(startTime, { tz: null, noTz: true, fromFormat: 'YYYY-MM-DD' });
        return currentDate.isSame(startTimeDate, 'day');
      };
      if (isSameDay(user?.profile?.subscription?.nextBillingDate)) return message(null, "No se permiten realizar modificaciones en la fecha de facturación.");
    }

    try {
      const subscription = user?.profile?.subscription;
      const cycleType = user?.cycleType?.toLowerCase() || (subscription?.typePlan?.toLowerCase());
      const ProgramsDetail = programa_seleccionado?.ProgramsDetail || subscription?.Program?.ProgramsDetail;
      const getAmount = cycleType === "mensual" ? programa_seleccionado?.cost_month : programa_seleccionado?.cost_biannual;
      const discount = state.cupon_detalles?.id ? parseFloat(calculateDiscount(getAmount, state.cupon_detalles?.discount, state.cupon_detalles?.type))?.toFixed(2) : null;

      const start_time = ["expired", "paused", "rejected", "cancelled"].includes(subscription?.status) ? (subscription?.subscriptionCancelDate || subscription?.nextBillingDate) : subscription?.nextBillingDate;
      const _subscription = await crear_suscripcion_paypal(null, {
        plan_id: cycleType === "mensual" ? ProgramsDetail?.pricePaypalMonthID : ProgramsDetail?.pricePaypalBiannualID,
        start_time: (!isEmpty(state.cupon_detalles?.id) || discount <= 0) ? undefined : ((isEmpty(subscription?.id) && isEmpty(state.cupon_detalles?.id)) ? dayjs_custom(null, { noTz: true }).add(7, 'day').toDate() : start_time),
        custom_id: invoiceCode.current,
        plan: state.cupon_detalles?.id ? {
          billing_cycles: [
            {
              sequence: 1,
              total_cycles: 0,
              pricing_scheme: {
                fixed_price: {
                  currency_code: "USD",
                  value: discount <= 0 ? 0.01 : discount
                }
              }
            },
          ]
        } : undefined,
        query_params: {
          cycleType: cycleType,
          userID: user?.userID,
          programID: programa_seleccionado?.id || user?.programID,
        }
      });

      return _subscription?.data?.id;
    } catch (error) {
      const _error = parseErrorMessage(error, true);
      message(null, _error);
      return null;
    }
  }, [user, programa_seleccionado, state.cupon_detalles]);

  const onApprovePaypal = useCallback(async (_data) => {
    if (_data?.subscriptionID) {
      if (type === "comprar-suscripcion") {
        try {
          const _comprar_suscripcion = await comprar_suscripcion({
            couponID: user?.couponID || state.cupon_detalles?.id,
            userID: user?.userID,
            programID: user?.programID,
            cycleType: user?.cycleType,
            paymentTransaction: _data?.orderID,
            subscriptionTransaction: _data?.subscriptionID,
            paypalToken: _data?.facilitatorAccessToken,
            methodPayment: "paypal",
          });

          if (_comprar_suscripcion.status) {
            // message("Suscripción actualizada", "¡La suscripción se ha actualizado exitosamente!");
            _onFinishUpdate();
          } else {
            message(null, "Error al realizar el pago");
          }
        } catch (error) {
          const _error = parseErrorMessage(error, false) || "Error al realizar el pago";
          message(null, _error);
        }
      } else if (type === "actualizar-programa") {
        try {
          const _actualizar_suscripcion = await updateSubscription({
            userID: user?.userID,
            programID: programa_seleccionado?.id,
            cycleType: user?.cycleType,
            subscription_id: _data?.subscriptionID,
            invoiceCode: invoiceCode.current,
          });

          if (_actualizar_suscripcion.status) {
            // message("Suscripción actualizada", "¡La suscripción se ha actualizado exitosamente!");
            _onFinishUpdate();
          } else {
            message(null, "Error al actualizar la suscripción");
          }
        } catch (error) {
          const _error = parseErrorMessage(error, false) || "Error al actualizar la suscripción";
          message(null, _error);
        }
      } else {
        try {
          const _actualizar_suscripcion = await actualizar_metodo_pago({
            id: user?.profile?.subscription?.id,
            userID: user?.userID,
            methodPayment: "paypal",
            invoiceCode: invoiceCode.current,
            subscriptionID: _data?.subscriptionID,
            paymentTransaction: _data?.orderID,
            paypalToken: _data?.facilitatorAccessToken,
          });

          if (_actualizar_suscripcion.status) {
            // message("Suscripción actualizada", "¡La suscripción se ha actualizado exitosamente!");
            _onFinishUpdate();
          } else {
            message(null, "Error al actualizar el método de pago");
          }
        } catch (error) {
          const _error = parseErrorMessage(error, false) || "Error al actualizar el método de pago";
          message(null, _error);
        }
      }
    }
  }, [user, type, state.cupon_detalles, programa_seleccionado?.id, _onFinishUpdate]);

  return (
    <PayPalReducer>
      {({ isPending }) => (
        <div className='flex flex-col items-center justify-center gap-6 animate__animated animate__fadeIn'>
          <div className='inline-flex flex-col gap-2'>
            {(isPending) ? <Loading /> : (
              <>
                {(type === "comprar-suscripcion") && (
                  <div className="flex flex-col gap-2 mb-3">
                    <div className="relative rounded-lg bg-white border border-[#e6e6e6] focus-within:border-indigo-500 focus-within:ring-indigo-500 flex items-center transition-all">
                      <input
                        name="cupon"
                        type="text"
                        placeholder="Cupón"
                        className="autofill:shadow-[inset_0_0_0px_1000px_rgb(255,255,255)] !border-0 !outline-none !ring-0 placeholder-gray-500 px-3 py-3 rounded-lg text-sm text-gray-900 uppercase w-full transition-all"
                        value={state.metodo_pago.cupon}
                        onChange={(event) => setState({ cupon: event.target.value }, "metodo_pago")}
                      />
                      <div className="flex flex-row gap-1 justify-center">
                        {state.sending.cupon ? (
                          <ProgressSpinner style={{ width: '1rem', height: '1rem', margin: 0, marginRight: 10 }} strokeWidth="3" animationDuration=".5s" />
                        ) : (
                          <>
                            {state.cupon_detalles?.id && (
                              <button type="button" className='py-3 px-2 text-Crayola text-sm underline active:scale-90 transition-all' onClick={() => setState((prevState) => ({ ...prevState, cupon_detalles: null, metodo_pago: { ...prevState.metodo_pago, cupon: "" } }))}>Eliminar</button>
                            )}
                            <button type="button" className='py-3 pl-2 pr-3 text-Crayola text-sm underline active:scale-90 transition-all' onClick={() => onSearchCoupon(state.metodo_pago.cupon)}>Aplicar</button>
                          </>
                        )}
                      </div>
                    </div>
                    {state.cupon_detalles?.id && (
                      <div className='flex justify-end w-full'>
                        <p className='font-golos text-sm text-right'>
                          <span className='mr-1'>Descuento del cupón:</span>
                          <span className='text-red-600'>{state.cupon_detalles?.type === 'porcentaje' ? `${state.cupon_detalles?.discount}%` : `$${parseFloat(state.cupon_detalles?.discount)?.toFixed(2)}`}</span>
                        </p>
                      </div>
                    )}
                  </div>
                )}
                <PayPalButtons
                  style={{
                    shape: 'rect',
                    layout: 'vertical', //default value. Can be changed to horizontal
                  }}
                  createSubscription={(data) => createSubscriptionPaypal(data, user)}
                  onApprove={(data) => onApprovePaypal(data, user)}
                  onCancel={(data, actions) => actions.redirect()}
                  onError={() => { }}
                  forceReRender={[user, state.cupon_detalles]}
                />
                <p className='text-xs text-gray-500'>Elige PayPal para mayor comodidad y seguridad. Al hacerlo, permites que JimeFitMom realice futuros cargos en tu tarjeta según lo acordado.</p>
              </>
            )}
          </div>
        </div>
      )}
    </PayPalReducer>
  );
}

const ActualizarMetodoPago = ({ type, user, programas, methodSelected, onCancel, _onFinishUpdate, updateOptionsStripe }) => {
  return (
    <>
      {methodSelected === "card" && (
        <StripeForm type={type} user={user} programas={programas} onCancel={onCancel} _onFinishUpdate={_onFinishUpdate} updateOptionsStripe={updateOptionsStripe} />
      )}
      {methodSelected === "paypal" && (
        <PayPalForm type={type} user={user} programas={programas} onCancel={onCancel} _onFinishUpdate={_onFinishUpdate} />
      )}
    </>
  );
}

const App = (props) => {
  const [apiKeys, setApiKeys] = useCustomState(null);

  useEffect(() => {
    getApiKeys().then(async apiKeys => {
      apiKeysRemote = apiKeys;
      stripePromise = loadStripe(apiKeys.stripe);
      setApiKeys(apiKeys);
    });
    // eslint-disable-next-line
  }, []);

  if (!apiKeys) return <Loading containerClassName={"flex-1 h-full py-10"} />;

  return (
    <ActualizarSuscripcion apiKeysRemote={apiKeys} {...props} />
  );
};

export default App;