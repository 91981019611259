export const font_family_formats = 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Golos Text=Golos Text; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats';

export const default_font_stack = ['Golos Text', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'];

export const font_size_formats = '8px 9px 10px 11px 12px 14px 18px 24px 30px 36px 48px 60px 72px 96px';

export const line_height_formats = '1 1.2 1.4 1.5 1.6 1.7 1.8 1.9 2 2.2 2.4 2.6 2.8 3 3.5 4';

export const color_map = [
  '#BFEDD2', 'Light Green',
  '#FBEEB8', 'Light Yellow',
  '#F8CAC6', 'Light Red',
  '#ECCAFA', 'Light Purple',
  '#C2E0F4', 'Light Blue',
  '#2DC26B', 'Green',
  '#F1C40F', 'Yellow',
  '#E03E2D', 'Red',
  '#B96AD9', 'Purple',
  '#3598DB', 'Blue',
  '#169179', 'Dark Turquoise',
  '#E67E23', 'Orange',
  '#BA372A', 'Dark Red',
  '#843FA1', 'Dark Purple',
  '#236FA1', 'Dark Blue',
  '#ECF0F1', 'Light Gray',
  '#CED4D9', 'Medium Gray',
  '#95A5A6', 'Gray',
  '#7E8C8D', 'Dark Gray',
  '#34495E', 'Navy Blue',
  '#F2F1FB', 'AntiFlashWhite',
  '#F59DD7', 'Orchid',
  '#F5E1FC', 'PinkLace',
  '#FDF0FA', 'LavenderBlush',
  '#F3F1FC', 'Magnolia',
  '#7E73D6', 'Crayola',
  '#342566', 'DeepKoamaru',
  '#191E25', 'DarkJungleGreen',
  '#F11212', 'VividRed',
  '#000000', 'Black',
  '#ffffff', 'White'
]
