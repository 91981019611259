import React, { memo, useCallback, useContext, useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Carousel } from 'primereact/carousel';
import { Image } from "primereact/image";
import { Sidebar } from 'primereact/sidebar';
import ProfleIcon from "../assets/icons/profile.svg";
import { ReactComponent as BarsIcon } from '../assets/icons/bars-icon.svg';
import { ReactComponent as PrevIcon } from "../assets/icons/prev-icon.svg";
import { ReactComponent as NextIcon } from "../assets/icons/next-icon.svg";
import { ReactComponent as EditIcon } from "../assets/icons/icon-edit.svg";
import { ReactComponent as Icon01 } from "../assets/icons/home/icon01.svg";
import { ReactComponent as Icon02 } from "../assets/icons/home/icon04.svg";
import { ReactComponent as Icon03 } from "../assets/icons/programas/icon03.svg";
import { ReactComponent as Icon04 } from "../assets/icons/programas/icon04.svg";
import { ReactComponent as Icon07 } from "../assets/icons/programas/icon07.svg";
import { ReactComponent as Icon09 } from "../assets/icons/programas/icon09.svg";
import { ReactComponent as Icon10 } from "../assets/icons/programas/icon10.svg";
import { ReactComponent as StarIcon } from "../assets/icons/star-icon2.svg";
import { ReactComponent as PagoIcon } from "../assets/icons/pago2-icon.svg";
import { ReactComponent as LogoutIcon } from "../assets/icons/logout-icon.svg";
import Loading from "./Loading";
import { logout as logoutClientApi } from "../api/auth";
import { getFrases } from "../api/client/frases";
import { colors } from "../config/colors";
import { useCustomState } from "../hooks/useCustomState";
import { logout } from "../redux/reducers/authSlice";
import GlobalContext from "../contexts/GlobalContext";
import getPageHome from "../utils/getPageHome";
import { shuffleAndStore } from "../utils/shuffleArray";
import { isEmpty } from "../utils";

const LayoutCarouselLeft = memo(() => {
  const [state, setState] = useCustomState({
    loading: true,
    items: [],
    page: null
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = useCallback(async () => {
    const frases = await getFrases();

    const shuffledArray = shuffleAndStore(frases?.data || [], "frase_del_dia", false);

    setState({ items: shuffledArray, loading: false });
  }, [setState]);

  const changePage = useCallback((type) => {
    const _page = type === "next" ? state.page + 1 : state.page - 1;
    if (_page >= state.items?.length || (type === 'prev' && _page < 0)) return;

    setState({ page: _page || 0 });

    // eslint-disable-next-line
  }, [state.items, state.page]);

  const CarouselTemplate = useCallback((item) => (
    <div className="w-full">
      <p className="font-golos-medium text-white text-sm">{item.message}</p>
    </div>
  ), []);

  return (
    <div className="bg-Orchid p-6 space-y-1 rounded-xl">
      {state.loading ? (
        <Loading textClassName="!text-white" />
      ) : (
        <>
          <div>
            <div className="flex items-center justify-between space-x-10">
              <Image
                src={require("../assets/images/logo_pink.png")}
                imageClassName="max-w-[80%]"
              />
              <div className="flex items-center space-x-2">
                <div className="c-pointer pl-1 pr-2" onClick={() => changePage("prev")}>
                  <PrevIcon fill="#fff" width={8} />
                </div>
                <div className="c-pointer pl-2 pr-1" onClick={() => changePage("next")}>
                  <NextIcon fill="#fff" width={8} />
                </div>
              </div>
            </div>
            <p className="mt-2 text-white text-sm"><strong>HOY</strong>, {dayjs(new Date()).format("DD MMM, YYYY")}</p>
          </div>
          <Carousel
            value={state.items}
            numVisible={1}
            numScroll={1}
            className="custom-carousel"
            showIndicators={false}
            showNavigators={false}
            autoplayInterval={0}
            itemTemplate={CarouselTemplate}
            onPageChange={event => setState({ page: event?.page })}
            page={state.page}
          />
        </>
      )}
    </div>
  );
}, (prev, next) => prev?.extraData === next?.extraData);

const LayoutMenuLeft = memo(() => {
  const { setModal } = useContext(GlobalContext);
  const [state, setState] = useCustomState({
    isMenuMobileVisible: false,
  });
  const { user } = useSelector(state => state.app.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { classNameIsActiveMenu } = useMemo(() => ({
    classNameIsActiveMenu: (location, pathname, contains = false) => ((location?.pathname === pathname || (contains === true && location?.pathname?.indexOf(pathname) !== -1)) ? "font-golos-semibold text-Crayola" : "font-golos"),
  }), []);

  const cerrarSesion = useCallback(() => {
    logoutClientApi(user?.id);
    dispatch(logout());
    navigate('/');
  }, [user, dispatch, navigate]);

  const MenuNav = useCallback(() => (
    <div className="flex flex-col space-y-5 pt-7 px-6">
      <div className="flex items-center gap-3">
        <Icon04 width={12} />
        <Link to="/mi-cuenta" className={`${classNameIsActiveMenu(location, "/mi-cuenta")} hover:text-DeepKoamaru hover:underline transition-all`}>Inicio</Link>
      </div>
      <div className="flex items-center gap-3">
        <Icon03 width={12} />
        <Link to="/mi-cuenta/tu-programa" className={`${classNameIsActiveMenu(location, "/mi-cuenta/tu-programa", true)} hover:text-DeepKoamaru hover:underline transition-all`}>{["fitmom-posparto"].includes(user?.Program?.code) ? "Etapa 2" : "Tu Programa"}</Link>
      </div>
      <div className="flex items-center gap-3">
        <Icon01 width={12} />
        <Link to="/mi-cuenta/cores" className={`${classNameIsActiveMenu(location, "/mi-cuenta/cores", true)} hover:text-DeepKoamaru hover:underline transition-all`}>Core</Link>
      </div>
      <div className="flex items-center gap-3">
        <Icon10 width={12} />
        <Link to="/mi-cuenta/workouts" className={`${classNameIsActiveMenu(location, "/mi-cuenta/workouts", true)} hover:text-DeepKoamaru hover:underline transition-all`}>Workouts</Link>
      </div>
      {user?.Program?.code === "fitmom-embarazo" && (
        <div className="flex items-center gap-3">
          <Icon10 width={12} />
          <Link to="/mi-cuenta/parto" className={`${classNameIsActiveMenu(location, "/mi-cuenta/parto", true)} hover:text-DeepKoamaru hover:underline transition-all`}>Parto</Link>
        </div>
      )}
      <div className="flex items-center gap-3">
        <Icon07 width={12} />
        <Link to="/mi-cuenta/descargables" className={`${classNameIsActiveMenu(location, "/mi-cuenta/descargables")} hover:text-DeepKoamaru hover:underline transition-all`}>Descargables</Link>
      </div>
      <div className="flex items-center gap-3">
        <Icon02 width={12} />
        <Link to="/mi-cuenta/configuracion" className={`${classNameIsActiveMenu(location, "/mi-cuenta/configuracion")} hover:text-DeepKoamaru hover:underline transition-all`}>Mi Cuenta</Link>
      </div>
      <div className="flex items-center gap-3">
        <StarIcon fill={colors.Crayola} width={12} />
        <Link to="/mi-cuenta/mis-favoritos" className={`${classNameIsActiveMenu(location, "/mi-cuenta/mis-favoritos")} hover:text-DeepKoamaru hover:underline transition-all`}>Mis Favoritos</Link>
      </div>
      <div className="flex items-center gap-3">
        <PagoIcon width={12} />
        <Link to="/mi-cuenta/mis-recibos" className={`${classNameIsActiveMenu(location, "/mi-cuenta/mis-recibos")} hover:text-DeepKoamaru hover:underline transition-all`}>Mis Recibos</Link>
      </div>
      <div className="flex items-center gap-3">
        <Icon09 width={12} />
        <Link to="/mi-cuenta/mi-suscripcion" className={`${classNameIsActiveMenu(location, "/mi-cuenta/mi-suscripcion")} hover:text-DeepKoamaru truncate hover:underline transition-all`}>Mi Suscripción</Link>
      </div>

      <div className="flex items-center gap-3 pt-12">
        <LogoutIcon width={12} />
        <p className={`hover:text-DeepKoamaru hover:underline transition-all c-pointer`} onClick={cerrarSesion}>Cerrar sesión</p>
      </div>
    </div>
  ), [user, location, classNameIsActiveMenu, cerrarSesion]);

  const NivelComponent = useCallback(() => {
    if (user?.Program?.code === "fitmom-strength") return;

    return (
      <div className="flex px-3 md:px-6 w-full">
        <div className="flex items-center gap-3 bg-Orchid pl-6 rounded-full w-full">
          <div className="flex-1 py-3">
            {!isEmpty(user?.level) ? (
              <p className="font-golos-medium text-white text-sm text-left">Eres Nivel: <span className="font-golos-semibold capitalize">{user?.level || ""}</span></p>
            ) : (
              <p className="font-golos-medium text-white text-sm text-left">Seleccionar nivel</p>
            )}
          </div>
          <div className="rounded-r-full flex h-full active:bg-[#e674bf] transition-all">
            <div className="flex items-center py-3 pl-3 pr-5 c-pointer no_highlights active:scale-[90%] transition-all" onClick={() => setModal("seleccionar_nivel", null, null, { showModal: true })}>
              <EditIcon height={16} width={16} />
            </div>
          </div>
        </div>
      </div>
    );
  }, [user?.Program?.code, user?.level, setModal]);

  return (
    <>
      <div className="inline-flex md:hidden col-span-1 fixed top-0 left-0 z-10 w-full">
        <div className="flex flex-1 space-x-4 items-center bg-white pt-5 pb-6 px-8 border-t border-[#F5F5F5]">
          {/* <div className="flex-1">
            <p className="font-golos-medium text-sm text-DarkJungleGreen/80 m-0">Bienvenida</p>
            <p className="font-golos-semibold text-Crayola m-0">{`${user?.firstName} ${user?.lastName}`}</p>
          </div> */}
          <div className="flex-1 items-center">
            <Link to={getPageHome()}>
              <img src={require("../assets/images/logo2.png")} alt="Logo" className="w-full select-none pointer-events-none" style={{ maxWidth: 140 }} />
            </Link>
          </div>
          <div className="inline-block ml-auto c-pointer active:scale-90 transition ease-in-out" onClick={() => setState({ isMenuMobileVisible: true })}>
            <BarsIcon fill={"#7e73d6"} height={28} />
          </div>
        </div>
      </div>
      <Sidebar
        visible={state.isMenuMobileVisible}
        onHide={() => setState({ isMenuMobileVisible: false })}
        className="menu-close-padding-custom"
      >
        <div className="bg-white pb-10 rounded-xl space-y-7 divide-y divide-gris-9A9A9A">
          <div className="flex flex-col space-y-5 items-center justify-center">
            <div className="flex items-center space-x-6 px-6">
              <div>
                <Image
                  src={user?.image || ProfleIcon}
                  imageClassName="bg-gris-EEEEEE w-[56px] rounded-full"
                />
              </div>
              <div className="flex-1">
                <p className="font-golos-medium text-sm text-DarkJungleGreen/80">Bienvenida</p>
                <p className="font-golos-semibold text-Crayola">{`${user?.firstName} ${user?.lastName}`}</p>
              </div>
            </div>
            <NivelComponent />
          </div>
          <MenuNav />
        </div>
      </Sidebar>
      <aside className="hidden md:block col-span-3 space-y-4">
        <div className="bg-white py-10 rounded-xl space-y-7 divide-y divide-gris-9A9A9A">
          <div className="flex flex-col space-y-5 items-center justify-center">
            <div className="flex items-center space-x-6 px-6">
              <div>
                <Image
                  src={user?.image || ProfleIcon}
                  imageClassName="bg-gris-EEEEEE w-[56px] rounded-full"
                />
              </div>
              <div className="flex-1">
                <p className="font-golos-medium text-sm text-DarkJungleGreen/80">Bienvenida</p>
                <p className="font-golos-semibold text-Crayola">{`${user?.firstName} ${user?.lastName}`}</p>
              </div>
            </div>
            <NivelComponent />
          </div>
          <MenuNav />
        </div>
        <LayoutCarouselLeft />
      </aside>
    </>
  );
});

const Grid = ({ children }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-12 gap-3 pt-12 md:pt-6">
      <LayoutMenuLeft />
      <div className="col-span-1 md:col-span-9">
        {children}
      </div>
    </div>
  );
}

export default Grid;