import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import Grid from '../components/Grid';
import Loading from '../../../components/Loading';
import { getPosts, updatePost } from '../../../api/admin/blog';
import { optionsToast } from '../../../config/toast';
import WysiwygEditor from '../../../components/Editor/index';
import { useCustomState } from '../../../hooks/useCustomState';
import parseErrorMessage from '../../../utils/parseErrorMessage';
import obtenerNombreImagen from '../../../utils/obtenerNombreImagen';
const prefix = "/admin/blog";

const EditarEntrada = ({ isEditar }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    name: "",
    summary: "",
    content: "",
    portada_file: null,
  });

  const refFotografiaFile = useRef(null),
    editorRef = useRef(null);

  useEffect(() => {
    (async () => {
      const post = await getPosts({ id });
      if (post?.status === true && post?.data?.length > 0) {
        const _post = post?.data?.[0];

        setState({
          name: _post?.name,
          summary: _post?.summary,
          content: _post?.content,
          portada_file: [{ name: obtenerNombreImagen(_post?.image, true) }],
          loading: false,
        });
      }
    })();

    return () => {
      window.PrimeToast.onHide = null;
    }
  }, [id, setState]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const onUpdate = useCallback(async () => {
    if (isEditar !== true) return;

    const dataEditor = editorRef?.current?.getContent();
    const formDataFields = {
      name: state.name,
      image: ((state.portada_file instanceof FileList && state.portada_file?.length > 0) && [...state.portada_file]),
      summary: editorRef?.current?.getText(),
      content: dataEditor,
    };

    const _formData = new FormData();

    Object.entries(formDataFields).forEach(([key, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          if (["image"].includes(key)) {
            value.forEach(item => _formData.append(key, item));
          } else {
            value.forEach(item => _formData.append(key + '[]', item));
          }
        } else {
          _formData.append(key, value);
        }
      }
    });

    try {
      setState({ sending: true });

      const response = await updatePost(id, _formData);

      setState({ sending: false });

      if (response?.status === true) {
        window.PrimeToast.onHide = () => navigate(prefix);
        message('Post actualizado', '¡El post se ha actualizado exitosamente! Serás redirigido a la página de blog en breve...');
      } else {
        message('Ocurrió un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al actualizar el post. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrió un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al actualizar el post. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [id, isEditar, state, setState, navigate]);

  return (
    <>
      <SEO
        title={isEditar ? "Editar entrada" : "Ver entrada"}
      />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex items-center justify-between gap-4'>
            <h1 className='text-Crayola font-golos-medium text-3xl'>{isEditar ? "Editar entrada" : "Ver entrada"}</h1>
          </div>
          {state.loading ? (
            <Loading containerClassName="mt-14" textClassName="text-lg" />
          ) : (
            <div className='flex flex-col bg-white mt-10 mx-6 lg:mx-8 px-8 py-10 rounded-lg'>
              <div className='flex flex-col gap-4'>
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                  <input
                    type="text"
                    placeholder='Nombre de la entrada'
                    className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    value={state?.name}
                    onChange={(event) => setState({ name: event.target.value })}
                    disabled={!isEditar}
                  />
                  <div className='flex items-center justify-between gap-4 bg-Magnolia border-0 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all'>
                    <p className='truncate'>{(state.portada_file?.length > 0 && state.portada_file?.[0]?.name) || "Sube una fotografía"}</p>
                    <input ref={refFotografiaFile} type="file" hidden accept="image/*" style={{ display: "none" }} onChange={(event) => setState({ portada_file: event.target.files })} disabled={!isEditar} />
                    {isEditar && (<button className='underline' onClick={() => refFotografiaFile?.current?.click()}>Examinar</button>)}
                  </div>
                </div>
                <WysiwygEditor
                  ref={editorRef}
                  value={state.content}
                  disabled={!isEditar}
                />
              </div>
              <div className='flex justify-center items-center gap-3 mt-10'>
                <ButtonCustom
                  text={isEditar ? "CANCELAR" : "REGRESAR"}
                  classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                  onClick={() => navigate(prefix)}
                  withIconBack={!isEditar}
                />
                {isEditar && (
                  <ButtonCustom
                    text="ACTUALIZAR"
                    classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                    onClick={onUpdate}
                    loading={state.sending}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </Grid>
    </>
  );
};

export default EditarEntrada;