export const prefix = "jimefitmom-app_"; // Usado para LocalStorage Service & Redux Persist

// En desarrollo (dev) - Para cambiar al entorno de producción usar "true"
const isProduction = true;

// Configuración de las URL según el entorno
export const API_BASE_URL = isProduction
  ? 'https://api.pruebasjimefitmom.com' // https://api.jimefitmom.metodika.com.mx | https://api.jimefitmom.com
  : 'http://192.168.3.84:3001'; // http://localhost:3001 | http://192.168.1.3:3001

export const WEB_BASE_URL = isProduction
  ? 'https://pruebasjimefitmom.com' // https://fitmom.metodika.com.mx | https://jimefitmom.com
  : 'http://localhost:3000';

export const apiKeys = {
  captcha: "6LcnXQEqAAAAAPKZx4Mr666wyfHL7LQDtTW7o9I2",
  tinymce: "kdrlghvlxc2knxltcad8u3puvqmr5nk1oesguelun5g2idfe",
}

export const externalLinks = {
  opcionYoInternal: "https://opcionyo.com/jimefitmom",
  opcionYo: "https://opcionyo.trb.ai/wa/18zyT67",
  consultas: "https://calendly.com/jimenacantu",
  consultas_personalizada_alumnas: "https://calendly.com/jimenacantu/consultaalumna",
}

export const downloadApps = {
  app_store: "https://apps.apple.com/mx/app/jimefitmom-pre-y-posnatal/id1615435709?l=en&mt=8",
  play_store: "https://play.google.com/store/apps/details?id=com.jimena.jimefitmom&hl=en_US&gl=US",
}

export const socialMedia = {
  instagram: "https://www.instagram.com/jimefitmom",
  facebook: "https://www.facebook.com/jimefitmom",
  facebook_comunidad: "https://www.facebook.com/groups/794198741752880",
  youtube: "https://www.youtube.com/@jimefitmom",
  tiktok: "https://www.tiktok.com/@jimefitmom",
};