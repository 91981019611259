export const filePickerCallback = (callback) => {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');

  input.onchange = function () {
    const file = input.files[0];
    const reader = new FileReader();

    reader.onload = function (e) {
      const base64 = e.target.result;
      callback(base64, { alt: file.name });
    };

    reader.readAsDataURL(file);
  };

  input.click();
};