import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import Grid from '../components/Grid';
import Loading from '../../../components/Loading';
import { createPost } from '../../../api/admin/blog';
import { optionsToast } from '../../../config/toast';
import WysiwygEditor from '../../../components/Editor/index';
import { useCustomState } from '../../../hooks/useCustomState';
import { getStatusActive } from '../../../misc/getStatus';
import { isEmpty } from '../../../utils';
import parseErrorMessage from '../../../utils/parseErrorMessage';
const prefix = "/admin/blog";

const CrearEntrada = () => {
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    name: "",
    portada_file: null,
  });

  const refFotografiaFile = useRef(null),
    editorRef = useRef(null);

  useEffect(() => {
    (async () => {
      setState({
        listado_estados: await getStatusActive(),
        loading: false,
      });
    })();

    return () => {
      window.PrimeToast.onHide = null;
    }
  }, [setState]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const onCreate = useCallback(async () => {
    if (isEmpty(state.name)) {
      return message(null, 'El nombre es obligatorio.');
    }
    if (isEmpty(state.portada_file)) {
      return message(null, 'Selecciona una imagen para continuar.');
    }
    const dataEditor = editorRef?.current?.getContent();

    const formDataFields = {
      name: state.name,
      image: ((state.portada_file instanceof FileList && state.portada_file?.length > 0) && [...state.portada_file]),
      summary: editorRef?.current?.getText(),
      content: dataEditor,
    };

    const _formData = new FormData();

    Object.entries(formDataFields).forEach(([key, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          if (["image"].includes(key)) {
            value.forEach(item => _formData.append(key, item));
          } else {
            value.forEach(item => _formData.append(key + '[]', item));
          }
        } else {
          _formData.append(key, value);
        }
      }
    });

    try {
      setState({ sending: true });

      const response = await createPost(_formData);

      setState({ sending: false });

      if (response?.status === true) {
        window.PrimeToast.onHide = () => navigate(prefix);
        message('Post creado', '¡El post se ha creado exitosamente! Serás redirigido a la página de blog en breve...');
      } else {
        message('Ocurrió un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al crear el post. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrió un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al crear el post. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [state, setState, navigate]);

  return (
    <>
      <SEO
        title="Nueva entrada"
      />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex items-center justify-between gap-4'>
            <h1 className='text-Crayola font-golos-medium text-3xl'>Nueva entrada</h1>
          </div>
          {state.loading ? (
            <Loading containerClassName="mt-14" textClassName="text-lg" />
          ) : (
            <div className='flex flex-col bg-white mt-10 mx-6 lg:mx-8 px-8 py-10 rounded-lg'>
              <div className='flex flex-col gap-4'>
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                  <input
                    type="text"
                    placeholder='Nombre de la entrada'
                    className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    value={state?.name}
                    onChange={(event) => setState({ name: event.target.value })}
                  />
                  <div className='flex items-center justify-between gap-4 bg-Magnolia border-0 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all'>
                    <p className='truncate'>{(state.portada_file?.length > 0 && state.portada_file?.[0]?.name) || "Sube una fotografía"}</p>
                    <input ref={refFotografiaFile} type="file" hidden accept="image/*" style={{ display: "none" }} onChange={(event) => setState({ portada_file: event.target.files })} />
                    <button className='underline' onClick={() => refFotografiaFile?.current?.click()}>Examinar</button>
                  </div>
                </div>
                <WysiwygEditor ref={editorRef} />
              </div>
              <div className='flex justify-center items-center gap-3 mt-10'>
                <ButtonCustom
                  text="CANCELAR"
                  classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                  onClick={() => navigate(prefix)}
                />
                <ButtonCustom
                  text="PUBLICAR"
                  classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                  onClick={onCreate}
                  loading={state.sending}
                />
              </div>
            </div>
          )}
        </div>
      </Grid>
    </>
  );
};

export default CrearEntrada;