import React, { useImperativeHandle, useCallback, memo, forwardRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { apiKeys } from '../../../config/app';
import { editorConfig } from './tinymceConfig/editorConfig';

const TinyEditorComponent = forwardRef(({ disabled, value, onChange, onLoad }, ref) => {
  const editorRef = React.useRef(null);

  useImperativeHandle(ref, () => ({
    getContent: (format = 'html') => {
      if (editorRef.current) {
        return editorRef.current.getContent({ format });
      }
      return null;
    },
    getText: () => {
      if (editorRef.current) {
        return editorRef.current.getBody().textContent;
      }
      return null;
    },
    setContent: (html) => {
      if (editorRef.current) {
        return editorRef.current.setContent(html);
      }
      return null;
    },
  }), []);

  const handleEditorInit = useCallback(async (_, editor) => {
    editorRef.current = editor;
  }, []);

  return (
    <div className={disabled ? 'select-none' : ''}>
      <Editor
        apiKey={apiKeys.tinymce}
        onInit={handleEditorInit}
        initialValue={value}
        onChange={onChange}
        onLoadContent={onLoad}
        scriptLoading={{ async: true }}
        init={editorConfig}
        disabled={disabled}
      />
    </div>
  );
});

export default memo(TinyEditorComponent);