import React, { forwardRef, useRef } from 'react';
import TinyEditorComponent from './TinyEditor/TinyEditorComponent';

const WysiwygEditor = forwardRef(({ value, onChange, onLoad, disabled }, ref) => {
  const editorRef = useRef();

  return (
    <TinyEditorComponent
      ref={ref || editorRef}
      value={value}
      onChange={onChange}
      onLoad={onLoad}
      disabled={disabled}
    />
  );
});

export default WysiwygEditor;